import Button from 'components/Button';
import { ButtonType } from 'components/Button/type';
import { ArrowBack } from 'components/Icons/Icons';
import { ReactNode } from 'react';

type ErrorPageTypes = {
    backButton: {
        href: string;
        text: string;
    };
    title: string;
    children: ReactNode;
};

export const ErrorPage = ({ backButton, title, children }: ErrorPageTypes) => (
    <div className="p-6 mx-auto max-w-3xl">
        <div className="flex pb-2">
            <Button link={{ href: backButton.href }} buttonType={ButtonType.Blue} className="flex items-center">
                <ArrowBack className="mr-1.5 h-6 w-6 pt-0.5" />
                {backButton.text}
            </Button>
        </div>

        <div className="flex flex-col mx-auto max-w-3xl bg-grey-20 rounded-md shadow-xl p-6 px-8">
            <h1 className="text-3xl text-center mb-2">{title}</h1>
            {children}
        </div>
    </div>
);
