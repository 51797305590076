import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { VulcanResponse } from 'src/queries/types';
import { vulcanApiRequest } from 'src/queries/utils';

export const sendEvent = async ({
    id,
    event_name
}: {
    id?: string;
    event_name: string;
}): Promise<VulcanResponse<null>> => {
    if (!id) return { success: false, status: 400, data: null, message: 'Id is required' };
    return await vulcanApiRequest(`/users/${id}/mailchimp/event`, { data: { event_name }, method: 'POST' });
};

export const useSendMailChimpEvent = (autoSend: boolean, id?: string, event_name = 'hits_mobile_paywall') => {
    const { mutate: sendMailChimpEvent } = useMutation(sendEvent);

    useEffect(() => {
        if (!!id && autoSend) {
            sendMailChimpEvent({ id, event_name });
        }
    }, [id, autoSend]);
};
