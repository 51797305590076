'use client';

import { useTrackTitanHydratedStore } from 'src/providers/track-titan-store-provider';
import { useTrackTitanStore } from 'src/zustand/store';
import { WarningRounded } from 'components/Icons/Icons';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/type';
import { useRouter } from 'next/navigation';
import * as Sentry from '@sentry/nextjs';
import { PropsWithChildren, useEffect } from 'react';
import ReportIssueModal from 'components/ReportIssueModal';
import { postReportIssue } from 'src/queries/session/reportIssue';
import { sendEvent } from 'src/hooks/useSendMailChimpEvent';

export function ErrorComponent({
    error,
    reset,
    title,
    children
}: PropsWithChildren<{ error: Error & { digest?: string }; reset: () => void; title?: string }>) {
    const { reportIssueModalOpen } = useTrackTitanHydratedStore((state) => state.analysis);
    const { user } = useTrackTitanHydratedStore((state) => state.auth);

    const { setReportIssueModalOpen } = useTrackTitanStore((state) => state.analysis);
    const router = useRouter();

    const onReportIssue = (issueReason: string) => postReportIssue(issueReason, window.location.href, user?.uuid);

    console.log(error);

    useEffect(() => {
        Sentry.captureException(error);
    }, [error]);

    return (
        <div className="flex flex-col h-full w-full items-center justify-center text-white">
            <WarningRounded className="w-18 h-18 m-2 text-orange-600" />
            <h1 className="text-2xl mb-3 text-center">{title ? title : 'Something has gone wrong'}</h1>
            {children}
            <div>
                <div className="flex flex-shrink flex-wrap md:flex-nowrap gap-4 m-8 justify-center md:justify-between">
                    <Button className="w-32" buttonType={ButtonType.White} onClick={() => reset()}>
                        Retry
                    </Button>
                    <Button
                        className="w-32"
                        buttonType={ButtonType.Normal}
                        onClick={() => {
                            router.push('/dashboard');
                        }}
                    >
                        Dashboard
                    </Button>
                    <Button
                        className="w-32"
                        buttonType={ButtonType.Normal}
                        onClick={async () => {
                            setReportIssueModalOpen(true);
                            await sendEvent({ id: user?.uuid, event_name: 'report_issue_clicked' });
                        }}
                    >
                        Report Issue
                    </Button>
                </div>
                <ReportIssueModal
                    open={reportIssueModalOpen}
                    setOpen={setReportIssueModalOpen}
                    onReportIssue={onReportIssue}
                />
            </div>
        </div>
    );
}
