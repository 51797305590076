import { ChangeEvent } from 'react';

interface IInput {
    text: string;
    setText: (text: string) => void;
    name: string;
    label: string;
    placeholder?: string;
    type?: string;
    disabled?: boolean;
    error?: string;
    success?: string;
}

export const TextInput = ({
    text,
    setText,
    name,
    disabled,
    placeholder,
    label,
    type = 'text',
    error,
    success
}: IInput) => {
    const onTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };

    return (
        <div className="flex flex-col w-full">
            <label htmlFor={name}>
                {label}
                <div className="flex mt-2">
                    <input
                        autoComplete="on"
                        type={type}
                        id={name}
                        className="shadow appearance-none rounded disabled:opacity-75 w-full p-4 py-2 h-12 leading-tight focus:outline-none focus:shadow-outline bg-grey-20 hover:bg-grey-25 text-white disabled:text-gray-200 transition-all"
                        placeholder={placeholder}
                        disabled={disabled}
                        value={text}
                        onChange={onTextChange}
                    />
                </div>
            </label>

            {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
            {success && <p className="mt-1 text-sm text-green-600">{success}</p>}
        </div>
    );
};
