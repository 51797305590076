import { Dialog } from '@headlessui/react';
import React, { ReactNode, useEffect } from 'react';
import styles from './index.module.css';
import { Close } from 'components/Icons/Icons';

interface IModal {
    open: boolean;
    closeModal: () => void;
    children: ReactNode;
    maxWidth?: string;
    outerClassName?: string;
    cantClose?: boolean;
    controlIntercom?: boolean;
}

const Modal = ({
    open,
    closeModal,
    children,
    maxWidth = '84rem',
    outerClassName = '',
    cantClose = false,
    controlIntercom = false
}: IModal) => {
    useEffect(() => {
        if (typeof window.Intercom !== 'undefined' && controlIntercom) {
            window.Intercom('update', { hide_default_launcher: open });
        }
    }, [open, controlIntercom]);

    return (
        <Dialog
            as="div"
            className="fixed inset-0 overflow-y-auto"
            onClose={!cantClose ? closeModal : () => {}}
            open={open}
            style={{ zIndex: 500000 }}
        >
            <div className="min-h-screen px-2 sm:px-4 text-center" style={{ minHeight: '100dvh !important' }}>
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-50 " />

                {/* This element is to trick the browser into centering the modal contents. */}
                <span className="inline-block h-screen align-middle" aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className={`${styles.ModalContent} ${outerClassName}`}
                    style={{ maxWidth: `calc(min(${maxWidth}, 100vw))` }}
                >
                    {children}

                    {!cantClose && (
                        <button
                            type="button"
                            onClick={closeModal}
                            className="absolute top-2 right-2 hover:bg-grey-20 transition-all duration-300 p-1 rounded-full h-8 w-8 flex items-center justify-center"
                        >
                            <Close />
                        </button>
                    )}
                </div>
            </div>
        </Dialog>
    );
};

export default Modal;
