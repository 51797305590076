import Link from 'next/link';
import React, { ButtonHTMLAttributes, MouseEvent, ReactNode } from 'react';
import { sendAnalyticsEvent } from 'src/analytics/utils';
import styles from './index.module.css';
import { ButtonType } from './type';

interface IButton {
    children: ReactNode;
    link?: {
        href: string;
        external?: boolean;
        target?: string;
        rel?: string;
    };
    buttonType?: ButtonType;
    disabled?: boolean;
    className?: string;
    analytics?: {
        eventName: string;
        email?: string;
        userUuid: string;
    };
    onClick?: (event: MouseEvent) => void;
    type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
    pulse?: boolean;
}

const buttonTypeToClass = (buttonType?: ButtonType, pulse = false) => {
    switch (buttonType) {
        case ButtonType.Blue:
            return styles.ButtonContainerBlue;
        case ButtonType.Inverted:
            return styles.ButtonContainerInverted;
        case ButtonType.Blue:
            return styles.ButtonContainerBlue;
        case ButtonType.Red:
            return styles.ButtonContainerRed;
        case ButtonType.InvertedWhite:
            return styles.ButtonContainerInvertedWhite;
        case ButtonType.InvertedIcon:
            return styles.ButtonContainerInvertedIcon;
        case ButtonType.InvertedNoBorder:
            return styles.ButtonContainerInvertedNoBorder;
        case ButtonType.White:
            return styles.ButtonContainerWhite;
        case ButtonType.Green:
            return styles.ButtonContainerGreen;
        case ButtonType.Bordered:
            return styles.ButtonContainerBordered;
        default:
            return `${styles.ButtonContainerNormal} ${pulse ? styles.ButtonContainerNormalPulse : ''}`;
    }
};

const Button = ({
    children,
    link,
    buttonType,
    disabled,
    className = '',
    analytics,
    onClick = () => {},
    type = 'button',
    pulse = false
}: IButton) => {
    const onButtonClick = (event: MouseEvent) => {
        if (analytics) {
            sendAnalyticsEvent({
                uuid: analytics.userUuid,
                email: analytics.email,
                eventName: analytics.eventName
            });
        }
        onClick(event);
    };

    const buttonClassName = `${styles.ButtonContainer} ${
        disabled ? styles.ButtonContainerDisabled : buttonTypeToClass(buttonType, pulse)
    } ${className}`;

    if (link) {
        return !link.external ? (
            <Link
                href={link.href}
                className={buttonClassName}
                onClick={onButtonClick}
                target={link.target}
                rel={link.rel}
            >
                {children}
            </Link>
        ) : (
            <a href={link.href} className={buttonClassName} onClick={onButtonClick} target={link.target}>
                {children}
            </a>
        );
    }

    return (
        <button className={buttonClassName} type={type} onClick={onButtonClick}>
            {children}
        </button>
    );
};

export default Button;
