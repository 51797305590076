export enum ButtonType {
    Normal = 'normal',
    Blue = 'blue',
    Inverted = 'inverted',
    InvertedIcon = 'invertedicon',
    Red = 'red',
    InvertedWhite = 'invertedwhite',
    InvertedNoBorder = 'invertedNoBorder',
    White = 'white',
    Green = 'green',
    Bordered = 'bordered'
}
