import axios from 'axios';
import toast from 'react-hot-toast';

export const postReportIssue = async (issueReason: string, sessionSegmentUrl: string, userId?: string) => {
    try {
        await axios.post(`${process.env.NEXT_PUBLIC_GAIA_API}/api/v1/report-issue/webhook`, {
            userId,
            url: window.location.href,
            issueReason,
            sessionSegmentUrl
        });
        toast.success('Successfully reported issue');
    } catch (error) {
        toast.error('Failed to report issue');
    }
};
