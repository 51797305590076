'use client';

import { type ReactNode, createContext, useRef, useContext } from 'react';
import { useStore } from 'zustand';

import { type RootState, useTrackTitanStore } from 'src/zustand/store';

export type TrackTitanStoreApi = typeof useTrackTitanStore;

export const TrackTitanStoreContext = createContext<TrackTitanStoreApi | undefined>(undefined);

export interface ITrackTitanStoreProvider {
    children: ReactNode;
}

export const TrackTitanStoreProvider = ({ children }: ITrackTitanStoreProvider) => {
    const storeRef = useRef<TrackTitanStoreApi>();
    if (!storeRef.current) {
        storeRef.current = useTrackTitanStore;
    }

    return <TrackTitanStoreContext.Provider value={storeRef.current}>{children}</TrackTitanStoreContext.Provider>;
};

export const useTrackTitanHydratedStore = <T,>(selector: (store: RootState) => T): T => {
    const trackTitanStoreContext = useContext(TrackTitanStoreContext);

    if (!trackTitanStoreContext) {
        throw new Error(`useTrackTitanStore must be used within TrackTitanStoreProvider`);
    }

    return useStore(trackTitanStoreContext, selector);
};
