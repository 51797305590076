import React, { useState } from 'react';
import Modal from 'components/Modal';
import { TextInput } from 'components/TextInput';
import { ButtonType } from 'components/Button/type';
import Button from 'components/Button';
import { WarningRounded } from 'components/Icons/Icons';

interface ISetupModal {
    open: boolean;
    setOpen: (open: boolean) => void;
    onReportIssue: (reason: string) => Promise<void>;
}

const ReportIssueModal = ({ open, setOpen, onReportIssue }: ISetupModal) => {
    const [reason, setReason] = useState('');
    const [loading, setLoading] = useState(false);
    const closeModal = () => setOpen(false);

    const onReportIssueClick = () => {
        setLoading(true);
        onReportIssue(reason).then(() => {
            closeModal();
            setLoading(false);
        });
    };

    return (
        <Modal open={open} closeModal={closeModal} maxWidth="40rem">
            <div className="p-2">
                <h3 className="text-3xl mb-2">Report Issue</h3>
                <TextInput
                    text={reason}
                    setText={setReason}
                    name={'Issue Reason'}
                    label=""
                    placeholder="Issue Reason"
                />
                <div className="flex items-center justify-end mt-4">
                    <Button buttonType={ButtonType.InvertedIcon} onClick={onReportIssueClick} disabled={loading}>
                        <WarningRounded className="-ml-1 mr-3.5" />
                        Report Issue
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ReportIssueModal;
